import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../layouts/main-layout";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import ResponseService from "../services/ResponseService";
import ValueService from "../services/ValuesService";
import { Calendar } from "primereact/calendar";
import Downloader from "js-file-download";

import { format } from "date-fns";
import { isEqual } from "lodash/fp";
import { Card } from "primereact/card";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { getSelf, setSelf, getAccessToken, hasManagerOrSuperAdminRole, hasDeleteAccess } from "./../util/cache";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Observations() {
  const [order, setOrder] = useState("desc");
  const [page, setpage] = useState(0);
  const toast = useRef(null);

  const [sortField, setSortField] = useState("startdate");
  const [sortOrder, setSortOrder] = useState(null);

  const defaultFilter = {
    text: "",
    pageSize: 20,
    page: page,
    observerId: "",
    personSupported: "",
    staffMember: "",
    startTime: "",
    endTime: "",
    monthRange: "",
    location: "",
    sortField: sortField,
    sortDirection: order,
  };

  const [loading, setLoading] = useState(false);
  const [observations, setObservations] = useState({});
  const [globalFilterValue, onGlobalFilterChange] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const [isFilterVisible, setFilterVisibility] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [reload, setReload] = useState(false);
  const [observers, setObservers] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [personSupported, setPersonSupported] = useState([]);
  const [locations, setLocations] = useState([]);
  const [monthRange, setMonthRange] = useState([]);
  const [searchFilter, setSearchFilter] = useState(defaultFilter);
  const [filter, setFilter] = useState(defaultFilter);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [responseToDeleteId, setResponseToDeleteId] = useState(null);

  const onPageChange = (event) => {
    setSearchFilter({ ...filter, page: event.page });
    setFirst(event.first);
    setRows(event.rows);
  };
  const confirmDelete = (id) => {
    setResponseToDeleteId(id);
    setDeleteDialogVisible(true);
  };

  useEffect(() => {
    ValueService.getObservers().then((data) => setObservers(data));
    ValueService.getStaff().then((data) => setStaffMembers(data));
    ValueService.getLocations().then((data) => setLocations(data));
    ValueService.getResponseMonths().then((data) => setMonthRange(data));
    ValueService.getPersonSupported().then((data) => setPersonSupported(data));
  }, []);

  //observation API
  useEffect(() => {
    const UserData = getSelf();
    const Access = hasDeleteAccess(UserData);
    setDeleteAccess(Access);
    setLoading(true);

    ResponseService.getResponses(searchFilter)
      .then((result) => {
        let end = (result.pageNumber + 1) * result.pageSize;
        setObservations({
          data: result.data.map((e) => {
            return {
              id: e.id,
              startDate: format(new Date(e.startTime), "dd/MM/yyyy"),
              startTime: format(new Date(e.startTime), "hh:mm:ss aa"),
              finishTime: format(new Date(e.finishTime), "hh:mm:ss aa"),
              organization: e.serviceOrganization,
              address: e.address,
              staffMember: e.staffMemberObserved,
              personSupported: e.personSupportedObserved,
              totalScore: parseFloat(e.totalScore || "0"),
            };
          }),
          start: result.pageNumber * result.pageSize + 1,
          end: result.totalRecordCount < end ? result.totalRecordCount : end,
          total: result.totalRecordCount,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchFilter, reload, page]);

  const onClickDownload = () => ResponseService.excelExport(searchFilter).then((data) => Downloader(data, "ResponsesExport.xlsx"));

  //search function
  const onClickSearch = () => {
    setSearchFilter({ ...filter, text: globalFilterValue });
  };

  //referesh the table
  const refreshObservationTable = () => {
    setReload(!reload);
    //  onClickSearch()
  };

  //clear filter
  const onClickClearFilter = () => {
    setFilter(defaultFilter);
    setSearchFilter(defaultFilter);
    refreshObservationTable();
  };

  function areObjectsEqualExceptPage(obj1, obj2) {
    const keysToIgnore = ["page"];
    for (const key in obj1) {
      if (!keysToIgnore.includes(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }

  //hasdifferent
  const hasDifferentFilters = !areObjectsEqualExceptPage(defaultFilter, filter);

  //delete function
  const deleteResponse = () => {
    if (responseToDeleteId) {
      ResponseService.deleteResponse(responseToDeleteId)
        .then((response) => {
          if (response.ok) {
            // Delete the response from the frontend after successful deletion
            const updatedData = observations.data.filter((row) => row.id !== responseToDeleteId);
            setObservations((prevObservations) => ({
              ...prevObservations,
              data: updatedData,
            }));
          }
          refreshObservationTable();
          toast.current.show({ severity: "success", summary: "Sucess", detail: "Observation Deleted!" });
          // Handle errors or feedback to the user if necessary
        })
        .catch((error) => {
          toast.current.show({ severity: "error", summary: "Failed!", detail: error.response.data.Exception, life: 3000 });
          console.error("Error deleting response:", error);
          refreshObservationTable();
        });
    }
    // Close the confirmation dialog
    setDeleteDialogVisible(false);
  };

  const renderHeader = (
    <div className="flex flex-wrap flex-column md:flex-row justify-content-start md:justify-content-between gap-2">
      <div className="flex gap-3 flex-column md:flex-row">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText className="w-full" value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e.target.value)} placeholder="Search" />
        </span>
        <Button iconPos="right" icon="pi pi-search" onClick={onClickSearch} />
        <Button
          label="Filters"
          className={classNames(hasDifferentFilters ? "p-button p-button-success flex" : "p-button-outlined p-button-success flex")}
          icon="pi pi-filter"
          iconPos="left"
          onClick={() => setFilterVisibility(true)}
        />
        <Button
          label="Clear Filters"
          // disabled={isEqual(searchFilter, defaultFilter)}
          className="p-button-outlined p-button-danger flex"
          icon="pi pi-filter-slash"
          iconPos="left"
          onClick={onClickClearFilter}
        />
      </div>

      <div className="flex">
        <Button label="Download Reports" className="w-full p-button-outlined p-button-success" icon="pi pi-download" iconPos="left" onClick={onClickDownload} />
      </div>
    </div>
  );

  const ResponseView = ({ id }) => {
    const [data, setData] = useState([]);
    const [overallComment, setOverollComment] = useState([]);
    useEffect(() => {
      ResponseService.getResponse(id).then(({ questions,comment }) =>{ 
        setOverollComment(comment)
        setData(questions)});
    }, [id]);

    return (<>
 
      <DataTable value={data} size="small" responsiveLayout="scroll">
        <Column field="questionText" header="Question" className="text-xs" />
        <Column field="answer" header="Answer" className="text-xs" />
        <Column field="comment" header="Comment" className="text-xs" />
      </DataTable>
      <span> <b>overall comment:</b> {overallComment}</span>
      </>
    );
  };

  const rowExpansionTemplate = ({ id }) => {
    return <ResponseView id={id} />;
  };

  const onsortSecondFunc = (event) => {
    const newSortDirection = order === "desc" ? "asc" : "desc";
    setOrder(newSortDirection);
    setSearchFilter({ ...filter, sortField: event.sortField, sortDirection: newSortDirection });
  };
  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    const newSortDirection = order === "desc" ? "asc" : "desc";
    setFilter({ ...filter, sortField: event.sortField, sortDirection: newSortDirection });
    onsortSecondFunc(event);
  };




  //filter side bar
  const sidebar = (
    <Sidebar onHide={() => setFilterVisibility(false)} position="right" visible={isFilterVisible} className="flex-start" icons={<h3 className="text-color p-0 m-0">Filters</h3>}>
      <div className="field mb-4">
        <label htmlFor="byObserver">By observer</label>
        <Dropdown
          id="byObserver"
          className="align-items-center w-full"
          value={filter.observerId}
          showClear={true}
          options={observers}
          onChange={(e) => setFilter(Object.assign(structuredClone(filter), { observerId: e.value ? e.value : "" }))}
          placeholder="Select observer"
        />
      </div>
      <div className="field mb-4">
        <label htmlFor="byStaff">By worker</label>
        <Dropdown
          id="byStaff"
          className="align-items-center w-full"
          value={filter.staffMember}
          showClear={true}
          options={staffMembers}
          onChange={(e) => setFilter(Object.assign(structuredClone(filter), { staffMember: e.value ? e.value : "" }))}
          placeholder="Select staff member"
        />
      </div>
      {/* <!-- add in person supported here --> */}
      <div className="field mb-4">
        <label htmlFor="byStaff">By Person Supported</label>
        <Dropdown
          id="byPersonSupported"
          className="align-items-center w-full"
          value={filter.personSupported}
          showClear={true}
          options={personSupported}
          onChange={(e) => setFilter(Object.assign(structuredClone(filter), { personSupported: e.value ? e.value : "" }))}
          placeholder="Select person Supported"
        />
      </div>
      <div className="field mb-4">
        <label htmlFor="byLocation">By location</label>
        <Dropdown
          id="byLocation"
          className="align-items-center w-full"
          value={filter.location}
          showClear={true}
          options={locations}
          onChange={(e) => setFilter(Object.assign(structuredClone(filter), { location: e.value ? e.value : "" }))}
          placeholder="Select location"
        />
      </div>
      <div className="field mb-4">
        <label htmlFor="byMonthRange">By month range</label>
        <Dropdown
          id="byMonthRange"
          className="align-items-center w-full"
          value={filter.monthRange}
          showClear={true}
          options={monthRange}
          onChange={(e) => setFilter(Object.assign(structuredClone(filter), { monthRange: e.value ? e.value : "" }))}
          placeholder="Select month range"
        />
      </div>
      <div className="field mb-4">
        <label htmlFor="byDateRange">By date range</label>
        <div id="byDateRange" className="flex flex-row gap-2">
          <Calendar showButtonBar placeholder="From" value={filter.startTime} onChange={(e) => setFilter(Object.assign(structuredClone(filter), { startTime: e.value }))} readOnlyInput />
          <Calendar showButtonBar placeholder="To" value={filter.endTime} onChange={(e) => setFilter(Object.assign(structuredClone(filter), { endTime: e.value }))} readOnlyInput />
        </div>
      </div>
      <Button
        label="APPLY FILTERS"
        className="p-button-success align-items-center w-full py-3"
        iconPos="left"
        onClick={() => {
          setSearchFilter(Object.assign({}, filter));
          setFilterVisibility(false);
        }}
      />
    </Sidebar>
  );

  const paginatorLeft = (
    <div style={{ display: "flex" }}>
      <div>
        <Button type="button" className="mx-4 pt-2" icon="pi pi-refresh" text onClick={refreshObservationTable} />
      </div>
      <div style={{ marginTop: "10px" }}>
        len
        <span className="ml-auto mb-2" style={{ paddingBottom: "20px" }}>
          Showing {observations.data ? observations.start : 0} to {observations.end} of {observations.total} entries
        </span>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    <>
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={(e) => {
          confirmDelete(rowData.id);
        }}
      />
    </>
  );

  const deleteDialogFooter = (
    <div>
      <Button label="No" icon="pi pi-times" onClick={() => setDeleteDialogVisible(false)} />
      <Button label="Yes" icon="pi pi-check" onClick={deleteResponse} />
    </div>
  );

  //paginator
  const paginator = (
    <div>
      <Paginator first={observations.start} rows={5} totalRecords={observations.total} onPageChange={onPageChange} />
    </div>
  );

  const headerTemplate = ({ category }) => {
    return <div className="flex align-items-center gap-2">{category}</div>;
  };

  //expandable testing
  const onRowExpand = (event) => {
    toast.current.show({ severity: "info", summary: "Observation details loaded.", detail: event.data.name, life: 3000 });
  };

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onPage = (event) => {
    setFirst(event.first);
  };


  const sortAndLoadData = (columnName) => {
    // Check if the clicked column is already the sorting field
    // If yes, toggle the sorting order; otherwise, set the sorting field to the clicked column
    if (sortField === columnName) {
      setSortOrder(sortOrder === 1 ? -1 : 1);
    } else {
      setSortField(columnName);
      setSortOrder(1); // Default to ascending order when changing sorting field
    }
  };

  return (
    <Layout breadcrumbs={["Saved Observations"]}>
      {sidebar}
      <div className="grid py-4 px-4 surface-500 flex-grow-1 mt-0">
        <div className="col-12">
          <Card className="observation-card-dataTable">
            <DataTable
              lazy
              stripedRows
              loading={loading}
              value={observations.data}
              first={first}
              header={renderHeader}
              sortField={sortField}
              sortOrder={sortOrder}
              paginatorLeft={paginatorLeft}
              onRowExpand={onRowExpand}
              expandedRows={expandedRows}
              rowGroupHeaderTemplate={headerTemplate}
              currentPageReportTemplate="{first}` to {last} of {totalRecords}"
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => setExpandedRows(e.data)}
              emptyMessage="No Results."
              responsiveLayout="scroll"
              onPage={onPage}
              onSort={onSort}>
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column field="startDate" header="Start Date" sortable onClick={() => sortAndLoadData("startDate")} />
              <Column field="address" header="Address" sortable onClick={() => sortAndLoadData("address")} />
              <Column sortable field="staffMember" header="Worker" onClick={() => sortAndLoadData("staffMember")} />
              <Column sortable field="personSupported" header="Person supported" onClick={() => sortAndLoadData("personSupported")} />
              <Column sortable field="totalScore" header="Score" onClick={() => sortAndLoadData("totalScore")} />
              <Column field="startTime" header="Start time" onClick={() => sortAndLoadData("startTime")} />
              <Column field="finishTime" header="Finish time" onClick={() => sortAndLoadData("finishTime")} />
              <Column sortable field="organization" header="Organisation" onClick={() => sortAndLoadData("organization")} />

              {deleteAccess ? <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "2rem" }}></Column> : null}
            </DataTable>
            <div className="col-12 " style={{ display: "flex", justifyContent: "end" }}>
              <div>
                <Button type="button" className="p-button-outlined p-button-primary flex mx-4 mt-2" icon="pi pi-refresh" severity="success" onClick={refreshObservationTable} />
              </div>
              <div style={{ marginTop: "20px" }}>
                <span className="ml-auto mb-2">
                  Showing{observations.total === 0 ? 0 : observations.start} to {observations.end} of {observations.total} entries
                </span>
              </div>
              <div>
                <Paginator first={observations.start} rows={20} totalRecords={observations.total} onPageChange={onPageChange} />
              </div>
            </div>
          </Card>
          <Toast ref={toast} />
          <Dialog visible={isDeleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} header="Confirm Delete" footer={deleteDialogFooter}>
            Are you sure you want to delete this observation?
          </Dialog>
        </div>
      </div>
    </Layout>
  );
}